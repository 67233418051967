@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  background-color: white;
}
.btn-business{
  margin-top: 7rem;
  width: 10rem;
  height: 2.1rem;
  color: black !important;
  background-color: cornflowerblue;
  border-color: white;
  font-weight: bold;

}
.btn-location{
  margin-top: 7rem;
  width: 10rem;
  height: 2.1rem;
  background-color: white;
  color: black;
  border-color: black;
  font-weight: 700;

}

.wrapper{

  text-align: center;
  border-radius: 5px 5px 5px 5px;
  
}

.roller{
  color: black;
  margin-right: 10rem;
  font-family: 'Poppins';font-size: 18px;
}
.roller3{
	color: black;
	margin-right: 9rem;
	font-family: 'Poppins';font-size: 18px;
  }
  .roller2{
	color: skyblue;
	margin-right: 9rem;
	font-family: 'Poppins';font-size: 18px;
  }
 
  .box{
	  border: 2px 2px 2px 2px;
  }
  
.logo{
  width: 2rem;
  
}
.online{
  color: black;
  
}
.abc{

  font-weight: bold !important;
  font-size: 17px !important;
  color: black !important;
}


.card2{
	background-color: #F7F7F5;
	
}
.card-body{
	padding-bottom: 1rem;
	
	
}


.tile{
	width: 100%;
	background:#fff;
	border-radius:5px;
	box-shadow:0px 2px 3px -1px rgba(151, 171, 187, 0.7);
	float:left;
  	-webkit-transform-style: preserve-3d;
  	        transform-style: preserve-3d;
  	margin: 10px 5px;

}

.header{
	border-bottom:1px solid #ebeff2;
	padding:19px 0;
	text-align:center;
	color:#59687f;
	font-size:600;
	font-size:19px;	
	position:relative;
}

.banner-img {
	padding: 5px 5px 0;
}

.banner-img img {
	width: 100%;
	border-radius: 5px;
}

.dates{
	border:1px solid #ebeff2;
	border-radius:5px;
	padding:20px 0px;
	margin:10px 20px;
	font-size:16px;
	color:#5aadef;
	font-weight:600;	
	overflow:auto;
}
.dates div{
	float:left;
	width:50%;
	text-align:center;
	position:relative;
}
.dates strong,
.stats strong{
	display:block;
	color:#adb8c2;
	font-size:11px;
	font-weight:700;
}
.dates span{
	width:1px;
	height:40px;
	position:absolute;
	right:0;
	top:0;	
	background:#ebeff2;
}
.stats{
	border-top:1px solid #ebeff2;
	background:#f7f8fa;
	overflow:auto;
	padding:10px 0;
	font-size:16px;
	color:#59687f;
	font-weight:600;
	border-radius: 0 0 5px 5px;
}
.stats div{
	border-right:1px solid #ebeff2;
	width: 33.33333%;
	float:left;
	text-align:center
}

.stats div:nth-of-type(3){border:none;}

div.footer {
	text-align: right;
	position: relative;
	margin: 20px 5px;
}

div.footer a.Cbtn{
	padding: 10px 25px;
	background-color: #DADADA;
	color: #666;
	margin: 10px 2px;
	text-transform: uppercase;
	font-weight: bold;
	text-decoration: none;
	border-radius: 3px;
}

div.footer a.Cbtn-primary{
	background-color: #5AADF2;
	color: #FFF;
}

div.footer a.Cbtn-primary:hover{
	background-color: #7dbef5;
}

div.footer a.Cbtn-danger{
	background-color: #fc5a5a;
	color: #FFF;
}

div.footer a.Cbtn-danger:hover{
	background-color: #fd7676;
}
